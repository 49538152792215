/**
 * @desc js 小盾风控接入
 * @tgStorageTime 有效值缓存时间

 * 实际页面取值处理
 * localStorage.getItem('tongdunBlackBox') || '';
 */
import ISONLINE from './domainJudge';
import { getFullUrl } from './utility';

(function () {
  try {
    // 全局挂载
    if (!window.TONGDun) {
      window.TONGDun = {};
    }
    if (!window.TONGDun.time) {
      window.TONGDun.time = {};
    }
    // 清除缓存信息
    window.TONGDun.clear = function () {
      localStorage.removeItem('tongdunAbleTime');
      localStorage.removeItem('tongdunBlackBox');
    };
    // 获取black_box方法
    window.TONGDun.getBlackBox = function () {
      const storage_black_box = localStorage.getItem('tongdunBlackBox') || '';
      // 当前处理为只用有效值，不采用降级值
      let black_box = '';
      if (storage_black_box.length <= 1024) {
        black_box = storage_black_box;
      } else {
        black_box = '';
      }
      return black_box;
    };
    // 初始化方法
    window.TONGDun.init = function (success) {
      // 开始同盾获取时间
      !window.TONGDun.time.initTime && (window.TONGDun.time.initTime = new Date().getTime());
      let tgEnv = 'test';
      if (ISONLINE) {
        tgEnv = 'online';
      }
      console.log('tongdun环境', tgEnv);

      // h5 （移动端） 或者 web （pc端）
      const UA = window.navigator.userAgent;
      const IsAndroid = /Android|HTC/i.test(UA);
      const IsIPad = !IsAndroid && (/iPad/i.test(UA) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
      const IsIPhone = !IsAndroid && /iPod|iPhone/i.test(UA);
      let tgType = 'web';
      if (IsAndroid || IsIPad || IsIPhone) {
        tgType = 'h5';
      }
      console.log('tongdun设备类型', tgType);

      // 渠道区分
      const tgMap = {
        online: {
          h5: {
            partner: 'mars',
            appName: 'mars_h5'
          },
          web: {
            partner: 'mars',
            appName: 'mars_web'
          }
        },
        test: {
          h5: {
            partner: 'mars',
            appName: 'mars_h5'
          },
          web: {
            partner: 'mars',
            appName: 'mars_web'
          }
        }
      };

      const { partner } = tgMap[tgEnv][tgType];
      const { appName } = tgMap[tgEnv][tgType];

      console.log('tongdun partner和appName', partner, appName);

      // 缓存策略缓 有效black_box
      // const tgStorageTime = 7 * 24 * 60 * 60 * 1000; // 缓存7天
      const tgStorageTime = 60 * 60 * 1000; // 线上同盾异常，暂时只缓存1小时
      // var tgStorageTime = 1; // 只缓存一天
      const tgCurTime = new Date().getTime(); // 当前时间
      const tgPrevTime = localStorage.getItem('tongdunAbleTime') || false; // 上次缓存时间
      console.log(tgCurTime, tgPrevTime);
      const tongdunBlackBox = window.TONGDun.getBlackBox();
      if (!tongdunBlackBox || !tgPrevTime || (!!tgPrevTime && tgCurTime - Number(tgPrevTime) > tgStorageTime)) {
        console.log('tongdun init');
        // eslint-disable-next-line no-underscore-dangle
        window._fmOpt = {
          partner,
          appName,
          // token: `tongdun-${new Date().getTime()}-${Math.random().toString(16).substr(2)}`,
          // fpHost: 'https://usfp.tongdun.net',
          // fmb: true,
          success(data) {
            // 在成功完成采集后，success 回调中可以获取到 black_box
            // 这里可以设置客户需求的操作，传输 black_box 等操作
            // 获取设备指纹黑盒数据，并提交到业务服务器 console.log('black_box: ', data)
            console.log('tongdun black_box: ', data);
            console.log('tongdun black_box: ', data.length);
            // 同盾获取完成时间
            window.TONGDun.time.ableTime = new Date().getTime();
            // 有效有效black_box
            if (data && data.length <= 1024) {
              // localStorage 处理
              localStorage.setItem('tongdunAbleTime', window.TONGDun.time.ableTime);
              localStorage.setItem('tongdunBlackBox', data);
            } else {
              console.log('tongdun black_box: ', '当前为降级值');
              localStorage.removeItem('tongdunAbleTime');
              localStorage.setItem('tongdunBlackBox', data);
            }
            // 回调
            if (success && success instanceof Function) {
              success(data);
            }
          }
        };
        const fm = document.createElement('script');
        fm.type = 'text/javascript';
        fm.async = true;
        // const fsrc = (tgEnv == 'online') ? getFullUrl('/app/js/dist/fm.js') : '//nqa.liveme.com/app/js/dist/fm.js';
        const fsrc = 'https://static.trustdecision.com/tdfp/us/fd816bbf5581579d90e390bc64d44a44/fm.js';
        fm.src = `${fsrc}?t=${(new Date().getTime() / 3600000).toFixed(0)}`;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(fm, s);
        fm.onload = () => {
          // fm.js加载完成时间
          window.TONGDun.time.fmLoaded = new Date().getTime();
        };
      } else {
        console.log('tongdun localStorage');
        // 同盾获取完成时间
        window.TONGDun.time.ableTime = new Date().getTime();
      }
    };
    // 防止重复初始化
    // eslint-disable-next-line no-underscore-dangle
    !window._fmOpt && window.TONGDun.init();
  } catch (err) {
    console.log('tongdun-err', err);
  }
}());
